isMobile = false;
if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) {
    isMobile = true;

}

function checkMobile() {
    if (isMobile) {
        $("body").addClass("is--mobile");
        $("body").removeClass("is--desktop");
    } else {
        $("body").removeClass("is--mobile");
        $("body").addClass("is--desktop");
    }
}
checkMobile();


$(window).resize(function() {
    checkMobile();
});

//menu scroll
let pt = $(".header").height()
window.addEventListener("scroll", function(e) {

    if ($(".header").length) {

        var navbar = $("header");
        var sticky = navbar[0].offsetTop;
        var st;
        var lastScrollTop = 0;

        function addFixedMenu() {

            if (window.pageYOffset >= 100) {
                navbar.addClass("fixed-menu");
                $("body").addClass("menu-is-fixed");

            } else {
                navbar.removeClass("fixed-menu");
                $("body").removeClass("menu-is-fixed");
            }
        }
        addFixedMenu();
        // if ($("body").height() > ($(window).height() + 400)) {
        //     st = $(this).scrollTop();
        //     addFixedMenu();
        //     lastScrollTop = st;
        // };
    }
});





function closemenuAll() {
    $(".nav__level-1 .open").removeClass("open");
    $(".nav__level-2 .active").removeClass("active");
    $(".nav-overlay").css("display", "none");
    $("body").removeClass("menu-is-open");
}


if (window.ontouchstart !== undefined) {
    $("body").addClass("menu-mobile");

    $(".navbar-toggler").click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        if ($(this).hasClass("collapsed")) {
            $(this).removeClass("collapsed");
            $(this).attr("aria-expanded", "true");
            $(".nav__level-1").slideDown();
        } else {
            $(this).addClass("collapsed");
            $(this).attr("aria-expanded", "false");
            $(".nav__level-3, .nav__level-2, .nav__level-1, .nav__mark").slideUp();

        }
    })

    $(".nav__level-1>li>.nav__btn").click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        if ($(this).hasClass("open")) {
            $(this).removeClass("open");
            $(this).siblings(".nav__level-2, .nav__mark").slideUp();
        } else {
            $(this).addClass("open");
            $(this).parent().siblings("li").find(".nav__level-2, .nav__mark").slideUp();
            $(this).siblings(".nav__level-2, .nav__mark").slideDown();
        }
    })
    $(".nav__level-2>li>.nav__btn").click(function(e) {
        e.preventDefault();
        e.stopPropagation();
        if ($(this).hasClass("open")) {
            $(this).removeClass("open");

            $(this).siblings(".nav__level-3, .nav__mark").slideUp();
        } else {
            $(this).addClass("open");
            $(this).parent().siblings("li").find(".nav__level-3").slideUp();
            $(this).siblings(".nav__level-3").slideDown();
        }
    })


} else {
    $(".header").append('<div class="nav-overlay"></div>');
    $(".nav__level-1>li>a").hover(function() {
        let submenu = $(this).siblings(".nav__level-2");
        let submenuMarks = $(this).siblings(".nav__mark");
        closemenuAll();

        if (submenu.length) {
            $(".nav-overlay").css("display", "block");
            $("body").addClass("menu-is-open");
            submenu.addClass("open");
            $(submenu).find(">li:first-child .nav__level-3").addClass("open");
            $(submenu).find(">li:first-child>a").addClass("active");
        }
        if (submenuMarks.length) {
            $(".nav-overlay").css("display", "block");
            $("body").addClass("menu-is-open");
            submenuMarks.addClass("open");
        }

    })
    $(".nav__level-2>li>a").hover(function() {

        $(".nav__level-3.open").removeClass("open");
        $(this).siblings(".nav__level-3").addClass("open");
        $(".nav__level-2 .active").removeClass("active");
        $(this).addClass("active");

    });
    $(".nav-overlay").hover(function() {
        closemenuAll();
    })

}

if ($("input[name='opcja_transakcja_mint']:checked").val() == 1) {
    $('#opcjeDodatkowe input').each(function() {
        $(this).attr("required", "required");
    })
}

// $(".form-input-onchange input").on("change", function(){
//     $(this).parents("form").submit();      
// })
// $(".select-onchange").on("change", function(){
//     $(this).parents("form").submit();      
// })

// $(".select-goToUrl").on("change", function(){
//     let hrefUrl=$(this).val();      
//    window.location.href=hrefUrl;
// })












$(document).ready(function() {



    if ($(".input-mask").length > 0) {
        $(".input-mask").mask('9-99-999999999-9');
    }
    if ($(".post-code-mask").length > 0) {
        $(".post-code-mask").mask('99-999');
    }


    //ukrycie przycisku zadzwoń do nas
    $('.adjust-help-info input').on('change', function() {

        if ($('.input-hide-button input').prop("checked") == true) {
            $('.input-hide-button .btn-blue').css("display", "block");
        }
        if ($('.input-hide-button input').prop("checked") == false) {
            $('.input-hide-button .btn-blue').css("display", "none");
        }
    });



    //zliczanie ceny

    $('#adjust-propert-choose input').click(function(e) {

        var suma = parseFloat($('.adjust-button #cenaProduktu').attr('cena'));

        $('#adjust-propert-choose .fieldset-dropdown input').each(function() {
            if ($(this).prop("checked") == true) {
                var addprice = parseFloat($(this).attr('cena'));
                suma = addprice + suma;

                return suma;
            }
        });

        $('.adjust-button #cenaProduktu').html(suma.toFixed(2).replace('.', ',') + '<small> zł</small>');


    });




    $(".star-box-m a").click(function(e) {
        $(".nav-tabs #opinie").click();
    });
    $(".zobacz-opis").click(function(e) {
        $(".nav-tabs #opis").click();
    });

    $('#add_insitution').click(function(e) {


        $('.add-institution-list').append(
            '<hr>' +
            '<ul class="list-label-top m-top-20">' +
            ' <li class="w-100">' +
            ' <label>Nazwa placówki</label>  ' +
            '<input type="text" name="nazwa[]" >' +
            ' </li>' +

            '<div class="f-j-sb">' +
            '<li>' +
            '<label>Ulica</label>  ' +
            ' <input type="text" name="ulica[]" >' +
            ' </li>' +
            '<li class="width-small" >' +
            ' <label>Kod</label>  ' +
            ' <input type="text" name="kod[]" >' +
            '</li>' +
            ' <li>' +
            ' <label>Miasto</label>' +
            ' <input type="text" name="miasto[]" >' +
            ' </li>' +
            ' </div>' +
            ' </ul>')
    });




    //brak ruchu myszką
    var timeout = null;
    $(document).on('mousemove', function() {
        clearTimeout(timeout);
        setTimeout(function() {
            $('a[data-target="#modal-alert"]').click();

        }, 5000);
    });

    var timeout = null;
    $(document).on('mousemove', function() {
        clearTimeout(timeout);
        timeout = setTimeout(function() {
            $('a[data-target="#modal-alert2"]').click();

        }, 5000);
    });




    //ad data


    function addCountDate(box) {
        let countDownDate = new Date(box.attr('data-date')).getTime();
        let stoper = box.siblings(".stoper");



        let x = setInterval(function() {

            let now = new Date().getTime();
            let distance = countDownDate - now;

            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);


            stoper.find(".days").html(days);
            stoper.find(".hours").html(hours);
            stoper.find(".minutes").html(minutes);
            stoper.find(".seconds").html(seconds);

        }, 0);
    }

    $(".final-date").each(function() {
        addCountDate($(this));
    });



    /* Hamburger menu animation */
    $('nav .mobile-menu').click(function() {
        $(this).toggleClass('open');
    });

    /* Menu fade/in out on mobile */
    $("nav .mobile-menu").click(function(e) {
        $("header .header-login div").slideUp("slow");
        $("header .header-basket div").slideUp("slow");

        // if ($(window).width() < 992) {
        //     $("header form").slideUp("slow");
        // }
        $("nav>.container").slideToggle("slow");
    });

    $('ul#maintabs>li:has(ul)>a').addClass('hasChildren');

    $("ul#maintabs>li:has(ul)>a").click(function(e) {
        e.preventDefault();
    });

    //rozwijanie drugiego poziomu menu
    if ($(window).width() > 992) {
        $("ul#maintabs>li").has("ul").hover(function() {


            $(this).children("ul").css("display", "block");
            $(this).children(":first").toggleClass("hasChildren");
            $(this).children(":first").toggleClass("hasChildren-open");

            if ($(window).width() > 992) {

                $(".menu-opacity").css({
                    "opacity": "1",
                    "display": "block"
                });
            }

        }, function() {
            $('ul#maintabs>li>ul').css("display", " none");
            $(this).children(":first").toggleClass("hasChildren");
            $(this).children(":first").toggleClass("hasChildren-open");
            $(".menu-opacity").css({
                "opacity": "0",
                "display": "none"
            });
        })
    }



    if ($(window).width() < 992) {
        $("ul#maintabs>li>ul>li>ul").css("display", "none");
        $('ul#maintabs li ul li:has(ul)>a').addClass('hasGrandChildren');

        $('ul#maintabs li ul li:has(ul)>a').click(function(event) {

            event.preventDefault();

        });

        $('ul#maintabs>li>ul>li:has(ul)>a').click(function(event) {
            event.preventDefault();
        });

        $("ul#maintabs>li:has(ul)>a").click(function() {


            $(this).toggleClass("hasChildren");
            $(this).toggleClass("hasChildren-open");
            $(this).siblings().toggleClass("d-block");



        });


        $("ul#maintabs>li>ul>li").has("ul").click(function() {
            $(this).find('a').addClass("hasGrandChildren");
            $(this).find('a').removeClass("hasGrandChildren-open");
            $('ul:eq(0)', this).toggleClass("d-block");
        });

    };

    //rozwijanie: moje konto, koszyk, szukaj


    $("header .header-login ").click(function(e) {
        e.stopPropagation();
        $("header .header-basket div").slideUp("fast");
        $("header .header-login div").slideToggle("fast");
        if ($(window).width() < 992) {
            // $("header form").slideUp("fast");

            // $("nav>.container").slideUp("slow");
            // $('nav .mobile-menu').addClass('open');
            if (!$(".navbar-toggler").hasClass("collapsed")) {
                $(".navbar-toggler").addClass("collapsed")
                $(".nav__level-1").slideUp("fast");
            }
        }

    });

    $("body:not(header .header-login)").click(function() {
        $("header .header-login div").slideUp("fast");
    });

    if ($(window).width() > 992) {
        $("header .header-basket ").hover(function(e) {
            e.stopPropagation();
            $("header .header-basket div").css("display", "block");
            $("header .header-login div").slideUp("fast");
            // if ($(window).width() < 992) {
            //     $("header form").slideUp("fast");
            // }
        }, function() {
            $("header .header-basket div").css("display", "none");
        });
    }


    $("body:not(header .header-basket div)").click(function() {
        $("header .header-basket div").css("display", "none");
    });


    $("body:not(header .header-basket)").click(function() {
        $("header .header-basket div").css("display", "none");
        $("header .header-login div").slideUp("fast");
    });

    // $("header .search-mobile ").click(function(e) {
    //     e.stopPropagation();
    //     $("header form").slideToggle("fast");
    //     $("header .header-login div").slideUp("fast");
    //     $("header .header-basket div").slideUp("fast");
    //     $('nav .mobile-menu').removeClass('open');
    //     $("nav>.container").slideUp("slow");
    //     $('nav .mobile-menu').addClass('open');
    // });

    if ($(window).width() < 768) {
        /*
        		$("body:not(header)").click(function () {
        			$("header form").slideUp("fast");
        		});
        */
    }

    $('.btn-filtr').click(function() {

        $(".menu-left").slideToggle("fast");
    });

    //scroll top



    let scrollTop = $(".scroll-top");

    /* $(window).scroll(function () {        
         let topPos = $(this).scrollTop(); 
         if (topPos > 100) {
              $(scrollTop).css("opacity", "1");
         } else {
              $(scrollTop).css("opacity", "0");          }

    }); */
    $(scrollTop).click(function() {
        $('html, body').animate({
            scrollTop: 0
        }, 800);
        return false;
    });


    //fixed menu-card product

    $(window).scroll(function() {
        if ($(window).scrollTop() >= 900) {
            $('.fixed-header').slideDown("fast");


        } else {
            $('.fixed-header').slideUp("fast");
        }
    });

    //show window - zapytaj o ten produkt


    $("body").on("click", ".product-print-proposal", function() {

        //  var pid = $(this).attr("id");
        var pid = $(this).attr("data-id-prod");
        var akcja = "oknoWygenerujWniosek";
        //alert('knock knock' + pid);
        $.get('/a', {
                a: akcja,
                produkt: pid
            },
            function(data) {
                $('.result-1').html(data);
            });
    });


    //     fieldset dropdown

    $('.fieldset-dropdown h4').click(function() {
        $(this).siblings().slideToggle();
        $(this).toggleClass('open');
    })

    $('input#dostosuj_1[type="radio"]').click(function() {
        if ($(this).prop("checked") == true) {
            $(".hide-content").slideDown();
            $(".hide-content-2").slideUp();
            $(".hide-content-3").slideUp();
        }
    });

    $('input#dostosuj_2[type="radio"]').click(function() {
        if ($(this).prop("checked") == true) {
            $(".hide-content-2").slideDown();
            $(".hide-content").slideUp();
            $(".hide-content-3").slideUp();
        }
    });

    $('input#dostosuj_3[type="radio"]').click(function() {
        if ($(this).prop("checked") == true) {
            $(".hide-content-3").slideDown();
            $(".hide-content").slideUp();
            $(".hide-content-2").slideUp();
        }
    });





    $('a[href="#tab6"').click(function() {
        $(".video").click();
    });


    $(".listProduct__btn").click(function() {

        $(this).toggleClass(".is--open");
        $(this).find("span").toggleClass("show");
        $(".listProduct__nav").slideToggle();

    })


    // $(".b2b__zoom-img--hover").hover(function(){
    //    let img= $(this).clone();
    //    img.addClass("b2b__zoom-img").removeClass("b2b__zoom-img--hover");
    //    $(this).after(img);
    // }, function(){$(".b2b__zoom-img").remove()})


    //zliczanie kliknięć
    function goToEc(productObj) {



        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
            'event': 'productClick',
            'ecommerce': {
                'click': {
                    'actionField': { 'list': 'Search Results' }, // Optional list property.
                    'products': [{
                        'name': productObj.nazwa, // Name or ID is required.
                        'id': productObj.id,
                        'category': productObj.kategoria,
                        'price': productObj.cena,
                        'brand': productObj.producent,
                        'position': productObj.pozycja
                    }]
                }
            },
            'eventCallback': function() {
                document.location = productObj.url
            }
        });
    };



    $(".list-content article a").click(function(e) {

        if ($(this).parents(".nfz__list-code")) return;
        e.preventDefault();
        let category = $(".list-content h1").data("category");
        let productId = $(this).parents("article.row").data("id");
        let productPosition = $(this).parents("article.row").data("position");


        $.get('/a', {
                a: "productClick",
                kategoria: category,
                produkt: productId,
                position: productPosition,
            },
            function(data) {

                if (data) {
                    goToEc(data);
                }
            })
    })


    $(".st__btn").click(function(e) {
        e.preventDefault();
        let id2 = $(this).data("id");
        let $this=$(this);
        $.get('/a', {
            a: "sklepTelefon",
            id: id2,
        }, function(data) {
            $this.css("display", "none");
            $(".st__link").css("display", "inline-block");          

        })
    })





});

let slideUp = (target, duration = 500) => {

    target.style.transitionProperty = 'height, margin, padding';
    target.style.transitionDuration = duration + 'ms';
    target.style.boxSizing = 'border-box';
    target.style.height = target.offsetHeight + 'px';
    target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    window.setTimeout(() => {
        target.style.display = 'none';
        target.style.removeProperty('height');
        target.style.removeProperty('padding-top');
        target.style.removeProperty('padding-bottom');
        target.style.removeProperty('margin-top');
        target.style.removeProperty('margin-bottom');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
        //alert("!");
    }, duration);
}

/* SLIDE DOWN */
let slideDown = (target, duration = 500) => {

    target.style.removeProperty('display');
    let display = window.getComputedStyle(target).display;
    if (display === 'none') display = 'block';
    target.style.display = display;
    let height = target.offsetHeight;
    target.style.overflow = 'hidden';
    target.style.height = 0;
    target.style.paddingTop = 0;
    target.style.paddingBottom = 0;
    target.style.marginTop = 0;
    target.style.marginBottom = 0;
    target.offsetHeight;
    target.style.boxSizing = 'border-box';
    target.style.transitionProperty = "height, margin, padding";
    target.style.transitionDuration = duration + 'ms';
    target.style.height = height + 'px';
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    window.setTimeout(() => {
        target.style.removeProperty('height');
        target.style.removeProperty('overflow');
        target.style.removeProperty('transition-duration');
        target.style.removeProperty('transition-property');
    }, duration);
}
function accordion(accordion) {

    let accordionContent = accordion.querySelector(".accordion__content");
    let accordionBtn = accordion.querySelector(".accordion__trigger");

    accordionBtn.addEventListener("click", function(e) {

        e.preventDefault();
        if (accordionBtn.getAttribute("aria-expanded") == "true") {

            accordionBtn.setAttribute('aria-expanded', false);
            accordionContent.setAttribute('aria-hidden', true);
            slideUp(accordionContent);

        } else {
            accordionBtn.setAttribute('aria-expanded', true);
            accordionContent.setAttribute('aria-hidden', false);
            slideDown(accordionContent);
        }
    })
}
Array.prototype.map.call(document.querySelectorAll(".js-accordion__panel"), function(item) { accordion(item) });